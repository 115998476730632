.plant-list-wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.clickable {
  cursor: pointer;
}
