
.top-bar-wrapper > section {
  padding: 0 10px;
}

.top-bar-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.last {
  width:100%;
  flex:1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}