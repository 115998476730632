.multi-pot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0;
}

.multi-pot-row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pot-plant {
  z-index: 5;
}
